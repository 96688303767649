<template>
	<div class="box">
		<div class="row">
	        <div class="col">
				<e-select
					id="entity_id"
					v-model="entity_selected"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('tiers.rechercher_billing_entity')"
					:selectedLabel="$t('global.selected_label')"
					:options="entity"
					:searchable="true"
					:allow-empty="true"
					:loading="loading_entities"
					:show-labels="false"
					:multiple="true"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="col">
				<e-select
					v-model="month_selected"
					:placeholder="getTrad('invoice.select_month')"
					:selectedLabel="getTrad('global.selected_label')"
					:options="months"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"
				>
					<template slot="option" slot-scope="{ option }">{{ $t('mois.'+option) }}</template>
					<template slot="singleLabel" slot-scope="{ option }">{{ $t('mois.'+option) }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="col">
				<e-select
					v-model="year_selected"
					:placeholder="getTrad('invoice.select_year')"
					:selectedLabel="getTrad('global.selected_label')"
					:options="years"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"
				/>
			</div>
	        <div class="col-2 ml-auto">
	            <button @click="loadExport" class="btn btn-block btn-primary">{{ $t('global.rechercher') }}</button>
	        </div>
	        <div v-if="invoices.length > 0" class="col-2 ml-auto">
	            <b-dropdown lazy variant="primary" class="w-100">
					<template v-slot:button-content>
						<font-awesome-icon v-if="loading_export" pulse :icon="['fas', 'spinner']" /> {{ $t('action.export') }} ▿
					</template>
					<b-dropdown-item @click.prevent.prevent="getExport">{{ $t('action.export_xml') }}</b-dropdown-item>
					<b-dropdown-item @click.prevent.prevent="getExportExcel">{{ $t('action.export_excel') }}</b-dropdown-item>
					<b-dropdown-item @click.prevent.prevent="getExportCSV(',')">{{ $t('action.export_csv_virgule') }}</b-dropdown-item>
					<b-dropdown-item @click.prevent.prevent="getExportCSV(';')">{{ $t('action.export_csv_pt_virgule') }}</b-dropdown-item>
				</b-dropdown>
	        </div>
		</div>

		<div v-if="launched" class="row mt-2">
			<LoadingSpinner v-if="loading" class="col-12" />

			<template v-else>
				<div v-if="invoices.length == 0" class="col text-center mt-3">{{ $t('global.no_result') }}</div>
				<div v-else class="accordion col-12" role="tablist">
					<b-card v-for="tiers in invoices" no-body class="mb-1" :key="tiers.tiers.tiers_id">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<div class="text-left p-1 row" v-b-toggle="'tiers-'+tiers.tiers.tiers_id">
								<div class="col-5">
									<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: tiers.tiers.tiers_id }}">
										{{ tiers.tiers.tiers_rs }}
									</router-link>
								</div>
								<div class="col-4 text-left">
									<template v-if="tiers.tiers.tiers_vat_number">
										<i>{{ tiers.tiers.tiers_vat_number }}</i> 
										<span v-if="!tiers.tiers.tiers_vatcheck" class="alert alert-warning ml-2 py-1 px-2" @click="showVATModal(tiers.tiers)"><font-awesome-icon :icon="['fal', 'exclamation-triangle']"/> {{ $t('invoice.vat_number_invalid') }} <font-awesome-icon :icon="['fal', 'edit']" class="ml-1"/></span>
									</template>
									<span v-else class="alert alert-warning py-1 px-2" @click="showVATModal(tiers.tiers)"><font-awesome-icon :icon="['fal', 'exclamation-triangle']"/> {{ $t('invoice.vat_number_empty') }} <font-awesome-icon :icon="['fal', 'edit']" class="ml-1"/></span>
								</div>
								<div class="col-auto">{{ tiers.paid }} / {{ tiers.total }}</div>
							</div>
						</b-card-header>
						<b-collapse :id="'tiers-'+tiers.tiers.tiers_id" accordion="accordion-tiers" role="tabpanel">
							<b-card-body class="p-1">
								<b-card-text>
									<div class="accordion" role="tablist">
										<b-card v-for="invoice in tiers.invoices" no-body class="mb-1" :key="invoice.invoice_id">
											<b-card-header header-tag="header" class="p-1" role="tab">
												<div class="text-left p-1 row" v-b-toggle="'invoice-'+invoice.invoice_id">
													<div class="col">
														<a href="" class="" @click.prevent="quickPreview(invoice.invoice_id)">
														    <font-awesome-icon :icon="['fas', 'eye']" />
									                    </a>
									                    {{ invoice.invoice_num }}
									                </div>
									                <div class="col">
									                	{{ invoice.invoice_date }}
									                </div>
													<div class="col-auto">{{ invoice.paid }} / {{ invoice.total }}</div>
												</div>
											</b-card-header>
											<b-collapse :id="'invoice-'+invoice.invoice_id" accordion="accordion-invoice" role="tabpanel">
												<b-card-body class="p-1">
													<b-card-text>
														<div v-for="detail in invoice.details" :key="detail.invoicedetails_id">
															{{ detail.articles.articles_label }} {{ detail.invoicedetails_ht }} 
														</div>
													</b-card-text>
												</b-card-body>
											</b-collapse>
										</b-card>
									</div>
								</b-card-text>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</template>
		</div>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
		<b-modal ref="tiers_vat_modal" hide-footer>
            <template v-slot:modal-title>
                {{ $t('tiers.num_tva') }}
            </template>

            <div class="row justify-content-around">
            	<div class="col-12 mt-2" :key="tiers_selected.tiers_id">
            		<label>{{ tiers_selected.tiers_rs }}</label>
                    <input type="text" class="form-control" v-model="tiers_selected.tiers_vat_number" :class="{ 'is-invalid': tiers_selected.error}">
                    <b-alert v-if="!tiers_selected.vat_valid && show_vat_warning" show variant="warning" class="mt-2">
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
                        {{ $t('tiers.invalid_format_vat') }}
                    </b-alert>
            	</div>
            	<div class="text-center mt-2">
					<b-button block pill variant="primary" :disabled="!vat_savable" @click="saveVATNumber"><font-awesome-icon v-if="processing_tva" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t("global.enregistrer") }}</b-button>
            	</div>
            </div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Invoice from "@/mixins/Invoice.js"
	import Common from '@/assets/js/common.js'
	import Tiers from "@/mixins/Tiers.js"
	import CSV from '@/mixins/Csv.js'

	export default {
		name: 'ExportDES',
		mixins: [Invoice, Tiers, CSV],
		data () {
			return {
				months : Array.from(new Array(12), (x,i) => i+1),
				years: Array.from({ length: 11}, (_, i) => (new Date().getFullYear()-10) + (i * 1)).reverse(), // 10 dernières années,
				month_selected: new Date().getMonth()+1,
				year_selected: new Date().getFullYear(),
				invoices: [],
				loading: false,
				launched: false,
				base64: null,
				loading_export: false,
				processing_tva: false,
                vat_savable: true,
				tiers_selected: {},
				show_vat_warning: true,
				loading_entities: false,
				entity_selected: null,
				entity: [],
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading_entities = true
				this.entity = await this.loadTiersEntity()
				this.entity_selected = this.entity
				this.loading_entities = false
			},

			async loadExport() {
				this.launched = true
				this.loading = true
				const invoices = await this.getExportDES(this.month_selected, this.year_selected, this.entity_selected.map(tiers => tiers.tiers_id))
				for(let i in invoices) {
					for(let j in invoices[i].invoices) {
						if(j == 0) {
							invoices[i].total = await this.priceFormat(invoices[i].total, invoices[i].invoices[j].author.tiers_currency, true)
							invoices[i].paid = await this.priceFormat(invoices[i].paid, invoices[i].invoices[j].author.tiers_currency, true)
						}
						invoices[i].invoices[j].total = await this.priceFormat(invoices[i].invoices[j].total, invoices[i].invoices[j].author.tiers_currency, true)
						invoices[i].invoices[j].paid = await this.priceFormat(invoices[i].invoices[j].paid, invoices[i].invoices[j].author.tiers_currency, true)
						invoices[i].invoices[j].invoice_date = this.formatDate(invoices[i].invoices[j].invoice_date)

						for(let k in invoices[i].invoices[j].details) {
							invoices[i].invoices[j].details[k].invoicedetails_ht = await this.priceFormat(invoices[i].invoices[j].details[k].invoicedetails_ht, invoices[i].invoices[j].author.tiers_currency, true)
						}
					}
				}
				this.invoices = invoices
				this.loading = false
			},

            async quickPreview(invoice_id) {
                this.$refs.modelPreview.show()
                let retour = await this.pdfInvoice([invoice_id], "", true)
                if(retour) {
                    this.base64 = retour
                }
            },

            async getExport() {
				this.loading_export = true
				await this.generateExportDES(this.month_selected, this.year_selected, this.entity_selected.map(tiers => tiers.tiers_id))
				this.loading_export = false
            },
			showVATModal(tiers) {
				this.$refs['tiers_vat_modal'].show()
				this.tiers_selected = tiers
			},
			async checkVATFormat() {
	            this.tiers_selected.tiers_vat_number = this.tiers_selected.tiers_vat_number.replace(/\W/g, '')
	            this.tiers_selected.vat_valid = this.checkVAT(this.tiers_selected.tiers_vat_number, this.tiers_selected.tiers_country)
	            this.tiers_selected.error = false
			},
			async saveVATNumber() {
				this.processing_tva = true
				if(this.tiers_selected.tiers_vat_number) {
					this.tiers_selected.error = await this.checkTvaValidOnline(this.tiers_selected.tiers_vat_number, this.tiers_selected.tiers_country) != 1
					if(!this.tiers_selected.error) {
						await this.editVatNumber(this.tiers_selected.tiers_id, this.tiers_selected.tiers_vat_number, this.tiers_selected.tiers_country)
					}
				}
				else {
					await this.editVatNumber(this.tiers_selected.tiers_id, this.tiers_selected.tiers_vat_number, this.tiers_selected.tiers_country)
				}

				if(this.tiers_selected.error) {
					this.failureToast('toast.vat_invalide')
					this.processing_tva = false
					return false
				}

				await this.$sync.force(true, false)
				this.processing_tva = false
				this.$refs['tiers_vat_modal'].hide()
				await this.loadExport()
			},

			async getExportExcel() {
				this.loading_export = true

				let invoices = []
				this.invoices.forEach(inv => {
					invoices = invoices.concat(inv.invoices)
				})
				const headers = {
					invoice_num: this.getTrad('invoice.desdeb_export.invoice_num'),
					invoice_date: this.getTrad('invoice.desdeb_export.invoice_date'),
					total: this.getTrad('invoice.desdeb_export.total'),
					paid: this.getTrad('invoice.desdeb_export.paid'),
					'tiers.tiers_rs': this.getTrad('invoice.desdeb_export.tiers_rs'),
					'tiers.tiers_vat_number': this.getTrad('invoice.desdeb_export.tiers_vat_number')
				}

				this.downloadJsonToExcel(
	                invoices,
	                headers,
	                `${'export_DES_' + this.month_selected + '_' + this.year_selected}.xls`,
	                [],
	                'export_DES'
	            )
				this.loading_export = false
			},

			async getExportCSV(delimiter) {
				this.loading_export = true

				let invoices = []
				this.invoices.forEach(inv => {
					invoices = invoices.concat(inv.invoices)
				})
				const headers = {
					invoice_num: this.getTrad('invoice.desdeb_export.invoice_num'),
					invoice_date: this.getTrad('invoice.desdeb_export.invoice_date'),
					total: this.getTrad('invoice.desdeb_export.total'),
					paid: this.getTrad('invoice.desdeb_export.paid'),
					'tiers.tiers_rs': this.getTrad('invoice.desdeb_export.tiers_rs'),
					'tiers.tiers_vat_number': this.getTrad('invoice.desdeb_export.tiers_vat_number')
				}

				this.downloadJsonToCsv(
	                invoices,
	                headers,
	                `${'export_DES_' + this.month_selected + '_' + this.year_selected}.csv`,
	                delimiter,
	                [],
	                'export_DES'
	            )
				this.loading_export = false
			}
		},
		watch: {
			// Code permettant la div d'erreur de s'afficher au bon moment & refresh l'affichage seulement quand la validité change pour empêcher le scintillement
			'tiers_selected.tiers_vat_number':{
				handler(val){
					const oldVatValid = this.tiers_selected.vat_valid
					this.checkVATFormat(val)
					if(oldVatValid ===  this.tiers_selected.vat_valid) return
					this.show_vat_warning = false
					this.$nextTick(() => {
						this.show_vat_warning = true
					})
				}
			}
		},
		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>